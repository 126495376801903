<template>
    <div>
      <page-header></page-header>
      <h1>Help / Utilities</h1>
    </div>
  
    <div class="row">
      <div class="col-md-12">
        <div class="explanation">
          <p>Note: this page is still in development.</p>
          <p>In the interim, there is documentation provided below based on the previous presentations.</p>
        </div>
      </div>
      </div>
  
      <div class="row">
      <div class="col-sm-6" >
        <div class="artificial-fieldset">
          <label class="artificial-fieldset-legend">Help</label>
          <table class="help-info-table">
            <tr>
              <button class="btn btn-primary rounded" id="assignTraineeButton" @click="openPDF('eLogbook_AirFrance_P1.pdf')">P1: Examiner</button>
            </tr>
            <tr>
              <button class="btn btn-primary rounded" id="assignTraineeButton" @click="openPDF('eLogbook_AirFrance_P2.pdf')">P2: Instructor/Trainee</button>
            </tr>
            <tr>
              <button class="btn btn-primary rounded" id="assignTraineeButton" @click="openPDF('eLogbook_AirFrance_P3.pdf')">P3: Examinable Tasks</button>
            </tr>
          </table>
      </div>
      </div>
  
      <div class="col-sm-6">
        <div class="artificial-fieldset">
          <label class="artificial-fieldset-legend">Utilities</label>
          <table class="help-info-table">
            <tr>
              <button class="btn btn-primary rounded" id="assignTraineeButton" @click="openExaminerHTMLModal">Text to HTML</button>
            </tr>
          </table>
        </div>
      </div>
    </div>
  
    <HTML-modal v-if="showExaminerHTML" @close="closeExaminerHTML"></HTML-modal>
  </template>
  
  
  <script>
  import PageHeader from './Header.vue';
  // import axios from 'axios';
  // import SignatureModal from './SignatureModal.vue';
  import HTMLModal from './ExaminerHTMLModal.vue';
  export default {
    name: 'ExaminerHelp',
    components: {
      PageHeader,
      // SignatureModal,
      HTMLModal,
    },
    data() {
      return {
        showExaminerHTML: false,
      };
    },
    methods: {
      openExaminerHTMLModal() {
      this.showExaminerHTML = true;
    },
    closeExaminerHTML() {
        this.showExaminerHTML = false; // Close the modal
      },
      openPDF(pdfName) {
        const pdfPath = require(`./pdfs/${pdfName}`);
        window.open(pdfPath.default);
      }
    }
  };
  </script>
  
  <style scoped>
  h1 {
    padding-top: 30px;
    padding-left: 3%;
    text-align: left; 
  }
  button.btn#assignTraineeButton {
    padding: 10px 10px;
    font-size: 16px;
    width: 200px;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-right: 20px;
    margin-left: 30px;
    border: 1px solid rgb(0, 96, 134);
    background: #2952c4;
    color: white;
    cursor: pointer;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
    float: left;
  }
  button.btn#assignTraineeButton:hover {
    background-color: #0c1a61;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  div p {
    text-align: left;
    margin-top: 20px;
    margin-left: 20px;
    padding: 20px;
    color: rgb(185, 3, 3);
    font-size: 16px;
  }
  div.explanation {
    border: 1px solid rgb(194, 193, 193);
    border-radius: 5px;
    margin: 10px 20px 10px 20px;
  }
  div.row {
    margin: 0px;
  }
  .artificial-fieldset {
    padding: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  </style>