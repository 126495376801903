<template>
  <div>
    <AdminHeader />
    <div class="container card">
      <h1>Add User</h1>
      <div class="add">
        <form @submit.prevent="addUser">
          <div class="form-group">
            <label for="name">First Name:</label>
            <input type="text" class="form-control rounded" id="name" name="name" placeholder="Enter First Name"
              v-model="newUser.first_name" />
          </div>
          <div class="form-group">
            <label for="name">Last Name:</label>
            <input type="text" class="form-control rounded" id="name" name="name" placeholder="Enter Last Name"
              v-model="newUser.last_name" />
          </div>
          <div class="form-group">
            <label for="role">Role:</label>
            <select class="form-control rounded" id="role" name="role" v-model="newUser.role">
              <option value="">Select User Role</option>
              <option value="examiner">Examiner</option>
              <option value="instructor">Instructor</option>
              <option value="assessor">Assessor</option>
              <option value="support_engineer">Support Engineer</option>
              <option value="trainee">Trainee</option>
            </select>
          </div>
          <div class="checkbox-container">
            <input v-if="newUser.role == 'instructor'" type="checkbox" id="assessorCheckbox" v-model="isAssessorCheckbox"
              :disabled="newUser.role !== 'instructor'">
              <label  v-if="newUser.role == 'instructor'" for="assessorCheckbox" :class="{'light-grey-label': newUser.role !== 'instructor'}">Assessor</label>
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="text" class="form-control rounded" id="email" name="email" placeholder="Enter Email Address"
              v-model="newUser.email" />
          </div>
          <div class="form-group">
            <label for="UIN">UIN:</label>
            <input type="text" class="form-control rounded" id="UIN" name="UIN" placeholder="Enter UIN"
              v-model="newUser.UIN" />
          </div>
          <div class="form-group">
            <label for="date_of_birth">Date of Birth:</label>
            <input type="date" class="form-control rounded" id="date_of_birth" name="date_of_birth" placeholder="YYYY-MM-DD" v-model="newUser.date_of_birth" />
          </div>
          <div class="form-group">
            <label for="place_of_birth">Place of Birth:</label>
            <input type="text" class="form-control rounded" id="place_of_birth" name="place_of_birth" placeholder="Enter Place of Birth" v-model="newUser.place_of_birth"/>
          </div>
          <div class="form-group">
            <div class="password-input">
              <label for="password">Password:</label>

              <input :type="showPassword ? 'text' : 'password'" class="form-control rounded" id="password" name="password"
                placeholder="Create Password" v-model="newUser.password" />
              <i class="eye-icon" @click="toggleShowPassword"
                :class="showPassword ? 'far fa-eye' : 'far fa-eye-slash'"></i>

            </div>
          </div>
          <div class="form-group">
            <label for="reenteredPassword">Re-enter Password:</label>
            <input :type="showPassword ? 'text' : 'password'" class="form-control rounded" id="reenteredPassword"
              name="reenteredPassword" placeholder="Re-enter Password" v-model="newUser.reenteredPassword" />
          </div>
          <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
            <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
          </div>
          <div>
            <button class="btn btn-primary rounded" type="submit">Add User</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>



<script>
import axios from 'axios';
import AdminHeader from './AdminHeader.vue';

export default {
  name: 'AddUser',
  components: {
    AdminHeader
  },
  data() {
    return {
      users: [],
      newUser: {
        first_name: '',
        last_name: '',
        role: '',
        password: '',
        reenteredPassword: '',
        email: '',
        UIN: '',
        date_of_birth: '',
        place_of_birth: '',
      },
      isAssessorCheckbox: false,
      showAlert: false,
      currentTime: '',
      showPassword: false,
      errorMessages: {},
      errorMessage: '',
      loggedInUser: '',
    };
  },
  methods: {
    onRoleChange() {
      if (this.newUser.role !== 'instructor') {
        this.isAssessorCheckbox = false;
      }
    },

    async addUser() {
      try {
        if (this.newUser.password !== this.newUser.reenteredPassword) {
          this.errorMessage = 'Passwords do not match';
          this.showAlert = true;
          return;
        }
        const isAssessor = (this.newUser.role === 'assessor') || (this.newUser.role === 'instructor' && this.isAssessorCheckbox);
        const response = await axios.post(
          '/api/users',
          {
            first_name: this.newUser.first_name,
            last_name: this.newUser.last_name,
            role: this.newUser.role,
            email: this.newUser.email,
            UIN: this.newUser.UIN,
            date_of_birth: this.newUser.date_of_birth,
            place_of_birth: this.newUser.place_of_birth,
            password: this.newUser.password,
            is_assessor: isAssessor,
          }
        );

        if (response.status === 200) {
          window.alert('User added Successfully')
          this.newUser = {
            first_name: '',
            last_name: '',
            role: '',
            email: '',
            password: '',
          };
          this.errorMessage = '';
          this.showAlert = false;
          this.isAssessorCheckbox = false;
        }
        await this.fetchUsers();
      } catch (error) {
        console.error('Error adding User:', error);
        console.log(error);


        if (error.response && error.response.data && error.response.data.errors) {
          // Handle validation errors
          const validationErrors = error.response.data.errors;

          // Build an error message from the validation errors
          let errorMessage = 'Validation errors:';
          for (const field in validationErrors) {
            errorMessage += `\n${field}: ${validationErrors[field][0]}`;
          }

          this.errorMessage = errorMessage;
        } else {
          // Handle other errors
          this.errorMessage = 'An error occurred while adding the user.';
        }

        this.showAlert = true;
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword; // Toggle the showPassword property
    },

    async fetchUsers() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/users', { headers });
        console.log(response);

        if (response.status === 200) {
          this.users = response.data.users;
        }
      } catch (error) {
        console.error('Error retrieving users:', error);
        // Handle error
      }
    },
    async fetchLoggedInUser() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get('/api/users', { headers });
        this.loggedInUser = response.data.username;
      } catch (error) {
        console.error('An error occurred while fetching the logged-in user:', error);
      }
    },
  },
  async mounted() {
    await this.fetchUsers();
    this.fetchLoggedInUser();
  },
};
</script>

<style scoped>
.container.card {
  top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 70%;
  bottom: 20px;
}

.view-trainees-button {
  background-color: #cfcfcf;
  /* Change the background color to your desired color */
  color: #000000;
  position: absolute;
  right: 3%;
  top: 10%;
  font-size: 14px;
}

.add button {
  width: 200px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #1b78db;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

input.form-control {
  width: 70%;
  margin-left: 180px;
  display: inline-block;
  margin-bottom: 25px;
  align-items: baseline;
  height: 40px;
}

select.form-control {
  width: 70%;
  margin-left: 180px;
  display: inline-block;
  margin-bottom: 43px;
  align-items: baseline;
  height: 40px;
}

.form-group {
  margin-bottom: 10px;
}

.checkbox-container {
  width: 15%; 
  margin-left: 23%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top:-2%;
  gap: 0.1em; /* This adds space between the checkbox and label */
}

/* Style for the checkbox */
.checkbox-container input[type="checkbox"] {
  /* Adjust the size with scale if needed */
  transform: scale(0.8);
  margin-left: 60px;
}
.checkbox-container input[type="checkbox"]:disabled{
  cursor: not-allowed;
}
/* Style for the label */
.checkbox-container label {
  margin-bottom: 26px;
  font-size: 0.9em; /* Adjust font size if needed */
  margin-left: 20px;
}

label {
  margin-right: 5px;
  position: absolute;
  margin-top: 8px;
}

.form-control {
  border-radius: 5px;
}


h1 {
  margin-bottom: 20px;
}

.eye-icon {
  position: absolute;
  top: 15px;
  right: calc(30px + 3%);
  cursor: pointer;
  color: grey;
}

.password-input {
  position: relative;
}

.light-grey-label {
  color: lightgrey;
}

</style>